<template>
  <b-pagination
    v-if="showPagination"
    id="table-pagination"
    :value="value"
    :per-page="perPage"
    :total-rows="totalRows"
    :hide-goto-end-buttons="!showSkipButtons"
    :class="[showSkipButtons ? 'longer' : '']"
    @input="handleInput"
  >
    <!--    <template v-slot:prev-text><span class="text-danger" @click.native="playSoundTab">Prev</span></template>-->
    <div slot="page" slot-scope="{ index }" @click="playSoundTab">
      {{ index + 1 }}
    </div>
    <template #first-text="{ disabled }">
      <div v-if="disabled" class="arrow skip left disabled ml-1"></div>
      <div v-else class="arrow skip left ml-1" @click="playSoundTab"></div>
    </template>
    <template #prev-text="{ disabled }">
      <div
        v-if="disabled"
        class="arrow left disabled"
        :class="[!showSkipButtons ? 'ml-1' : '']"
      ></div>
      <div
        v-else
        class="arrow left"
        :class="[!showSkipButtons ? 'ml-1' : '']"
        @click="playSoundTab"
      ></div>
    </template>
    <template #next-text="{ disabled }">
      <div
        v-if="disabled"
        class="arrow right disabled"
        :class="[!showSkipButtons ? 'mr-1' : '']"
      ></div>
      <div
        v-else
        class="arrow right"
        :class="[!showSkipButtons ? 'mr-1' : '']"
        @click="playSoundTab"
      ></div>
    </template>
    <template #last-text="{ disabled }">
      <div v-if="disabled" class="arrow skip right disabled mr-1"></div>
      <div v-else class="arrow skip right mr-1" @click="playSoundTab"></div>
    </template>
  </b-pagination>
</template>
<script>
export default {
  name: "TablePagination",
  props: {
    totalRows: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 5,
    },
    value: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      page: this.value,
    };
  },
  computed: {
    showPagination() {
      if (this.totalRows <= this.perPage) {
        return false;
      } else return true;
    },
    totalPages() {
      return this.totalRows / this.perPage;
    },
    showSkipButtons() {
      return this.totalPages > 5;
    },
  },

  // make sure the currentPage is updated on the parent component:
  methods: {
    handleInput(event) {
      this.$emit("input", event);
    },
  },
};
</script>

<style lang="scss">
#table-pagination {
  position: absolute;
  width: 259px;
  height: 62px;

  margin-bottom: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px 0 30px;
  -webkit-transform: translate3d(0, 0, 0);
  &.longer {
    width: 315px;
  }

  li {
    a,
    span {
      background-color: transparent;
      font-family: Ubuntu;
      font-size: 18px;
      color: #3a5162;
      border: 0;
      &:focus {
        outline: none;
      }
    }
  }

  .page-link {
    cursor: pointer;
    padding: 0;
    margin-left: 0;
    background-color: transparent;
    border: 0;
    color: #1e5963;
    font-size: 18px;
    &:focus {
      box-shadow: none;
    }
  }

  .page-item.active .page-link {
    color: $light-blue;
    font-family: Ubuntu-bold;
    font-size: 18px;
  }

  .arrow {
    width: 15px;
    height: 16px;
    background: url(~@/assets/img/common/icn-arrow-next.svg) center/100% 100%
      no-repeat;
    -webkit-transform: translate3d(0, 0, 0);
    filter: drop-shadow(0 3px 19px $light-blue)
      drop-shadow(0 3px 6px $light-blue)
      drop-shadow(0 3px 8px rgba(0, 0, 0, 0.5))
      drop-shadow(0 5px 8px rgba(0, 0, 0, 0.5));
    &.skip {
      width: 23px;
      background: url(~@/assets/img/common/icn-arrow-last.svg) center/100% 100%
        no-repeat;
      -webkit-transform: translate3d(0, 0, 0);
      // width: 57px;
      // height: 52px;
    }

    &.disabled {
      opacity: 0.3;
    }

    &.left {
      transform: scaleX(-1);
    }
  }
}
</style>
