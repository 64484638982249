<template>
  <div class="trading-card-back">
    <div class="card-back">
      <img class="card-bg" :src="card.image_urls.backgrounds.empty_url" />
      <div
        class="trading-card-title back to-center-abs"
        :class="{ 'two-lines': lineBreak }"
        v-html="cardTitle"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TradingCardBack",
  props: {
    card: {
      type: Object,
      required: true,
    },
  },

  computed: {
    cardTitle() {
      let localeTitle = this.$t(
        `trading_cards.cards.${this.card.set}_${this.card.number}`
      );
      let titleWords = localeTitle.split(" ");
      if (titleWords.length === 1) {
        return localeTitle;
      } else if (titleWords.length === 2) {
        return titleWords[0] + "<br />" + titleWords[1];
      } else if (titleWords.length === 3) {
        return titleWords[0] + " " + titleWords[1] + "<br />" + titleWords[2];
      } else if (titleWords.length === 4) {
        return (
          titleWords[0] +
          " " +
          titleWords[1] +
          " " +
          titleWords[2] +
          "<br />" +
          titleWords[3]
        );
      } else {
        return localeTitle;
      }
    },
    cardTitlePlain() {
      return this.$t(
        `trading_cards.cards.${this.card.set}_${this.card.number}`
      );
    },

    lineBreak() {
      return this.cardTitle.includes("<br />");
    },
  },
};
</script>
<style lang="scss">
.trading-card-back {
  position: relative;

  .card-bg {
    width: 100%;
  }

  .trading-card-title {
    color: #37728a;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    bottom: 23%;
    font-size: 8px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);

    &.two-lines {
      line-height: 1.2;
    }
  }
}
</style>
