<template>
  <b-col id="winners-container" cols="12">
    <b-row id="winners-content-row">
      <b-col id="overview-col" cols="12" class="pb-5">
        <OverviewResults
          v-if="userTickets"
          :error="logError"
          :user-tickets="userTickets"
          :user-logs="logs"
          :rewards="rewards"
          :date="nextRaffleDate"
        />
      </b-col>
      <b-col
        v-if="userTickets.length > 0"
        id="user-tickets-col"
        cols="12"
      ></b-col>
      <b-col id="results-col" cols="6">
        <RaffleResults :error="resultsError" :results="results" />
      </b-col>
      <b-col id="raffle-col" cols="6">
        <NextRaffle
          v-if="!nextRaffleError && nextRaffleDate"
          :items="rewards"
          :date="nextRaffleDate"
          :error="nextRaffleError"
        />
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import NextRaffle from "./next-raffle.vue";
import RaffleResults from "./raffle-results.vue";
import OverviewResults from "@/components/cards/overview-results";
import { fetchUserRaffleLogs } from "@/API/cards.js";
export default {
  name: "WinnersContainer",
  components: { OverviewResults, RaffleResults, NextRaffle },
  props: {
    nextRaffleDate: {
      type: Number,
      default: 0,
    },
    userTickets: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      rewards: [],
      results: [],
      logs: [],
      resultsError: false,
      nextRaffleError: false,
      logError: false,
    };
  },
  computed: {
    cardRaffleWinners: function () {
      return this.$store.getters["getGeneralConfig"].cardRaffle;
    },
    cardRaffleRewards: function () {
      return this.$store.getters["getGeneralConfig"].cardRaffleRewards;
    },
  },

  mounted() {
    Promise.all([
      this.fetchAllRaffleRewards(),
      this.fetchWinners(),
      this.fetchLogs(),
    ]).finally(() => this.$store.commit("setIsPageLoading", false));
  },

  methods: {
    fetchAllRaffleRewards() {
      return new Promise((resolve, reject) => {
        if (!this.cardRaffleRewards) {
          this.nextRaffleError = true;
          reject();
        } else {
          this.rewards = this.cardRaffleRewards.sort(function (a, b) {
            return b.amount - a.amount;
          });
          resolve();
        }
      });
    },
    fetchWinners() {
      return new Promise((resolve, reject) => {
        if (!this.cardRaffleWinners) {
          this.resultsError = true;
          reject();
        } else {
          let resultsArray = Object.values(this.cardRaffleWinners);
          resultsArray.forEach((result) =>
            result.sort(function (a, b) {
              return a.amount - b.amount;
            })
          );
          this.results = resultsArray;
          resolve();
        }
      });
    },
    fetchLogs() {
      return new Promise((resolve, reject) => {
        fetchUserRaffleLogs()
          .then((res) => {
            this.logs = Object.values(res.data.data.raffles);
            resolve(res);
          })
          .catch((e) => {
            console.log(e);
            this.logError = true;
            reject(e);
          });
      });
    },
  },
};
</script>

<style lang="scss">
.row-heading-large {
  font-family: Cinzel-Bold;
  width: 100%;
  top: -45px;
  font-size: 24px;
  text-align: center;
  color: transparent;
  background-image: linear-gradient(#f7ffff, #ffee51 58%, #8b5a00);
  background-clip: text;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5))
    drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ */
    & {
      background: transparent;
      color: rgb(253, 242, 141);
    }
  }
}
#winners-container {
  padding: 0;
  position: relative;
  .text-grey {
    color: #8e8e8e;
  }
  .text-red {
    color: #f92f2f;
  }
  .text-green {
    color: #43ff00;
  }
  .gold-coin {
    width: 16px;
    height: 16px;
    background-image: url("~@/assets/img/common/coin1.png");
  }
}
</style>
