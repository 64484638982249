var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showPagination)?_c('b-pagination',{class:[_vm.showSkipButtons ? 'longer' : ''],attrs:{"id":"table-pagination","value":_vm.value,"per-page":_vm.perPage,"total-rows":_vm.totalRows,"hide-goto-end-buttons":!_vm.showSkipButtons},on:{"input":_vm.handleInput},scopedSlots:_vm._u([{key:"page",fn:function(ref){
var index = ref.index;
return _c('div',{on:{"click":_vm.playSoundTab}},[_vm._v(" "+_vm._s(index + 1)+" ")])}},{key:"first-text",fn:function(ref){
var disabled = ref.disabled;
return [(disabled)?_c('div',{staticClass:"arrow skip left disabled ml-1"}):_c('div',{staticClass:"arrow skip left ml-1",on:{"click":_vm.playSoundTab}})]}},{key:"prev-text",fn:function(ref){
var disabled = ref.disabled;
return [(disabled)?_c('div',{staticClass:"arrow left disabled",class:[!_vm.showSkipButtons ? 'ml-1' : '']}):_c('div',{staticClass:"arrow left",class:[!_vm.showSkipButtons ? 'ml-1' : ''],on:{"click":_vm.playSoundTab}})]}},{key:"next-text",fn:function(ref){
var disabled = ref.disabled;
return [(disabled)?_c('div',{staticClass:"arrow right disabled",class:[!_vm.showSkipButtons ? 'mr-1' : '']}):_c('div',{staticClass:"arrow right",class:[!_vm.showSkipButtons ? 'mr-1' : ''],on:{"click":_vm.playSoundTab}})]}},{key:"last-text",fn:function(ref){
var disabled = ref.disabled;
return [(disabled)?_c('div',{staticClass:"arrow skip right disabled mr-1"}):_c('div',{staticClass:"arrow skip right mr-1",on:{"click":_vm.playSoundTab}})]}}],null,false,859271340)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }