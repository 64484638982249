<template>
  <div
    class="error-placeholder d-flex align-items-center justify-content-center drop-shadow-box"
  >
    <slot
      ><h3 class="text-white text-uppercase bold">
        {{ $t("generic_error_message") }}
      </h3></slot
    >
  </div>
</template>
<script>
export default {
  name: "ErrorPlaceholder",
};
</script>

<style lang="scss">
.error-placeholder {
  min-height: 400px;
  z-index: 3;
  position: relative;
  h3 {
    text-shadow: 0 2px 2px black;
  }
}
</style>
