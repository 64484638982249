<template>
  <div class="trading-card clickable">
    <div
      class="card-front"
      :class="{
        grey: cannotBeWished,
      }"
      @click="handleCardFrontClick"
    >
      <img class="card-bg" :src="card.image_urls.backgrounds.collected_url" />
      <div
        class="trading-card-title to-center-abs"
        :class="{ 'two-lines': lineBreak }"
        v-html="cardTitle"
      ></div>
      <img
        v-if="card.set"
        class="card-symbol"
        :src="card.image_urls.image_url"
      />
    </div>

    <div
      v-if="userCard && !userCard.is_full"
      class="card-badge counter d-flex align-items-center justify-content-center bold gradient-indent glow to-center-abs"
    >
      {{ userCard.amount }} x
    </div>
    <div
      v-else-if="userCard && userCard.is_full"
      class="card-badge counter complete gradient-indent glow d-flex align-items-center justify-content-center bold to-center-abs"
    >
      {{ userCard.amount }} x<img
        draggable="false"
        src="@/assets/img/cards/icon-set-complete.png"
      />
    </div>
  </div>
</template>

<script>
import InfoBadge from "@/components/base/info-badge.vue";
import * as CardsAPI from "@/API/cards.js";
export default {
  name: "TradingCardWish",
  props: {
    card: {
      type: Object,
      required: true,
    },
    userCard: {
      type: Object,
      required: false,
      default: null,
    },
  },

  computed: {
    cardTitle() {
      let localeTitle = this.$t(
        `trading_cards.cards.${this.card.set}_${this.card.number}`
      );
      let titleWords = localeTitle.split(" ");
      if (titleWords.length === 1) {
        return localeTitle;
      } else if (titleWords.length === 2) {
        return titleWords[0] + "<br />" + titleWords[1];
      } else if (titleWords.length === 3) {
        return titleWords[0] + " " + titleWords[1] + "<br />" + titleWords[2];
      } else if (titleWords.length === 4) {
        return (
          titleWords[0] +
          " " +
          titleWords[1] +
          " " +
          titleWords[2] +
          "<br />" +
          titleWords[3]
        );
      } else {
        return localeTitle;
      }
    },
    cardTitlePlain() {
      return this.$t(
        `trading_cards.cards.${this.card.set}_${this.card.number}`
      );
    },
    setTitle() {
      return this.card.set.charAt(0).toUpperCase() + this.card.set.slice(1);
    },
    lineBreak() {
      return this.cardTitle.includes("<br />");
    },
    cannotBeWished() {
      if (this.userCard) {
        return this.userCard.is_craftable;
      } else {
        return this.card.probability.probability === 1;
      }
    },
  },

  methods: {
    handleCardFrontClick() {
      this.playSoundTab();
      if (this.userCard && this.userCard.is_craftable) {
        this.$emit("maxAmountAlert");
      } else if (this.userCard && this.card.probability.probability === 1) {
        this.$emit("legendaryAlert");
      } else {
        let cardId;
        if (this.userCard) {
          cardId = this.userCard.card_id;
        } else {
          cardId = this.card.id;
        }
        CardsAPI.wishForCard(cardId)
          .then((res) => {
            this.$emit("wishSuccess", this.cardTitlePlain);
          })
          .catch((e) => {
            if (e.data.id) {
              this.$emit("cardWishError", this.$t(e.data.id));
            } else {
              this.$emit("cardWishError", this.$t("generic_error_message"));
            }
          });
      }
    },
  },
};
</script>
<style lang="scss">
.grey {
  filter: grayscale(100%);
  pointer-events: none;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ */
    &:before {
      content: "";
      background-color: rgba(136, 136, 136, 0.5);
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
</style>
