<template>
  <div id="countdown" class="countdown-container">
    <div class="countdown-box bg-img">
      <span class="countdown-text-content text-center blue medium">
        {{ $t(superscript) }}:
      </span>
      <span
        v-if="displayRemainingTime"
        class="countdown blue bold"
        :class="{ long: displayRemainingTime.length >= 12 }"
      >
        <div class="time-col position-relative">
          <div class="time-display days">{{ $t("time.day>other") }}</div>
          {{ displayRemainingTime.days }}
        </div>
        <div class="time-col">:</div>
        <div class="time-col position-relative">
          <div class="time-display hours">{{ $t("time.hour>other") }}</div>
          {{ displayRemainingTime.hours }}
        </div>
        <div class="time-col">:</div>
        <div class="time-col position-relative">
          <div class="time-display minutes">{{ $t("time.minute>other") }}</div>
          {{ displayRemainingTime.minutes }}
        </div>
        <div class="time-col">:</div>
        <div class="time-col position-relative">
          <div class="time-display seconds">{{ $t("time.second>other") }}</div>
          {{ displayRemainingTime.seconds }}
        </div>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Countdown",
  props: {
    time: {
      type: Number,
      required: true,
    },
    countdownType: {
      type: String,
      required: true,
    },
    withTimeDiff: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      now: Date.now(),
      intervalId: null,
    };
  },

  computed: {
    timeDiff() {
      return this.$store.getters["user/getTimeDifference"];
    },

    displayRemainingTime() {
      if (this.time === 0) {
        return;
      }

      let t = this.time - this.now;
      if (this.withTimeDiff) {
        t -= this.timeDiff;
      }

      let days = Math.floor(t / (1000 * 60 * 60 * 24));
      let hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((t % (1000 * 60)) / 1000);
      if (t < 0) {
        this.$emit("over");
        // window.clearInterval(this.intervalId);
        return this.$t("countdown.now") + "!";
      }
      return (
        // Formatted so that leading zeros aren't dropped
        {
          days: ("00" + days).slice(-2),
          hours: ("00" + hours).slice(-2),
          minutes: ("00" + minutes).slice(-2),
          seconds: ("00" + seconds).slice(-2),
        }
      );
    },

    superscript() {
      switch (this.countdownType) {
        case "raffle":
          return "trading_cards.raffle.next_time";
        case "wish":
          return "trading_cards.desire.next_time";

        case "league":
          return "user_leagues.matchday_ends_in";
        default:
          return "teams.battles.remaining_time";
      }
    },
  },
  created() {
    let self = this;
    this.intervalId = setInterval(function () {
      self.now = Date.now();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>
<style lang="scss">
.countdown-container {
  width: 570px;

  @media (max-width: 1446px) {
    margin-top: 30px;
  }

  .countdown-box {
    width: 100%;
    position: relative;
    height: 2rem;
    background-color: black;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    box-shadow: 0 3px 0 #44a5b7;
    border-radius: 8px;
    span {
      padding: 0 8px 0 8px;
    }

    .countdown-text-content {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      font-size: 14px;
      line-height: 1.2;
      font-family: Ubuntu-Medium;
    }
    .countdown {
      width: 85px;
      font-size: 18px;
      font-family: Ubuntu-Bold;
      display: inline-flex;

      .time-col {
        min-width: 50px;
        text-align: center;
      }

      .time-display {
        position: absolute;
        top: -1.6rem;
        text-transform: uppercase;
        font-size: 13px;
        font-family: Ubuntu-Bold;
        width: 50px;
        left: -4px;
        text-align: center;

        &.days {
          left: 1px;
        }
      }

      &.long {
        width: 125px;
        font-size: 16px;
      }
    }
  }
}
</style>
