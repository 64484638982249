<template>
  <b-col v-if="!loading && !error" id="album-container" cols="12"
    ><transition
      name="inspect-card"
      :duration="1000"
      @enter="enter"
      @leave="leave"
      ><CardInspector
        v-if="selectedCard"
        :key="selectedCard.id"
        :card="selectedCard"
        @close="selectedCard = null"
    /></transition>
    <div v-for="(set, index) in sets" :key="set.set" class="card-row-container">
      <SetRow
        :set-index="index"
        :user-cards="userCardsInSet(set.set)"
        :set="set"
        :cards="cardsInSet(set.set)"
        :user-tickets="userTickets"
        :wish-mode="wishMode"
        :raffle-rewards="raffleRewards"
        :redeemed-set="redeemedSet"
        @redeem="handleRedeem"
        @upgrade="handleUpgrade"
        @legendaryAlert="
          $store.commit('popups/setAlertBannerContent', {
            alertText: $t(
              'trading_cards.popup.not_desirable.legendary_message'
            ),
            alertHeading: $t('alerts.title.error'),
            variant: 'danger',
            type: 'danger',
          })
        "
        @maxAmountAlert="
          $store.commit('popups/setAlertBannerContent', {
            alertText: $t(
              'trading_cards.popup.not_desirable.max_amount_message'
            ),
            alertHeading: $t('alerts.title.error'),
            variant: 'danger',
            type: 'danger',
          })
        "
        @wishSuccess="handlewishSuccess"
        @selected="handleCardSelect"
      />
    </div>
  </b-col>
  <ErrorPlaceholder v-else-if="error" class="w-100" />
</template>

<script>
import * as CardsAPI from "@/API/cards.js";
import SetRow from "./set-row.vue";
import AnimationParsingFunctions from "@/components/animation/animation-parsing-functions";
import ErrorPlaceholder from "@/components/base/error-placeholder.vue";
import CardInspector from "@/components/cards/card-inspector.vue";

export default {
  name: "AlbumContainer",
  components: { SetRow, ErrorPlaceholder, CardInspector },
  mixins: [AnimationParsingFunctions],
  props: {
    wishMode: {
      type: Boolean,
      default: false,
    },
    userTickets: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      sets: [],
      cards: [],
      userCards: [],
      loading: true,
      error: false,
      reward: { amount: 0 },
      wishedCardTitle: "",
      selectedCard: null,
      redeemedSet: "",
      posX: "",
      posY: "",
    };
  },

  computed: {
    raffleRewards: function () {
      return this.$store.getters["getGeneralConfig"].cardRaffleRewards;
    },
  },

  mounted() {
    if (this.$store.getters["gameplay/cardsUpdateNeeded"]) {
      this.fetchCards().then((res) => {
        this.cards = res;
        this.$store.commit("gameplay/setTradingCards", res);
      });
    } else {
      this.cards = this.$store.state.gameplay.allTradingCards;
    }
    Promise.all([this.fetchSets(), this.fetchUserCards()])
      .then((values) => {
        this.sets = values[0];
        this.userCards = values[1];
        this.loading = false;
        this.$store.commit("setIsPageLoading", false);
      })
      .catch((e) => {
        console.log(e);
        this.loading = false;
        this.error = true;
      });
  },

  methods: {
    enter(el) {
      const tlIn = this.gsap.timeline();
      tlIn
        .set(el, {
          scale: 0,
          left: this.posX,
          top: this.posY,
        })
        .to(el, 0.8, {
          scale: 1,
          xPercent: -50,
          yPercent: -50,
          left: "50%",
          top: "58%",
          ease: "back",
        });
    },
    leave(el) {
      const tlOut = this.gsap.timeline();
      tlOut.to(el, 1, {
        opacity: 0,
        y: 200,
        ease: "power2",
      });
    },

    async fetchSets() {
      let response = await CardsAPI.fetchSets();
      return response;
    },
    async fetchCards() {
      let response = await CardsAPI.fetchCards();
      return response;
    },
    async fetchUserCards() {
      let response = await CardsAPI.fetchUserCards();
      let filteredResponse = response.filter((card) => card.amount > 0);
      return filteredResponse;
    },

    cardsInSet(setName) {
      return this.cards.filter((card) => card.set === setName);
    },
    userCardsInSet(setName) {
      return this.userCards.filter((card) => card.card.set === setName);
    },

    handleRedeem(set) {
      this.playSoundButtonUpcraft();
      CardsAPI.exchangeSet(set)
        .then((res) => {
          this.reward = res.data.data.reward;
          this.userCards = res.data.data.cards.filter(
            (card) => card.amount > 0
          );
          if (this.reward.type === "gold") {
            this.mapApiJsonCardRedeem(res.data.data);
          } else {
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: this.$t(
                "trading_cards.popup.redeem.message.ticket_received"
              ),
              alertHeading: this.$t("alerts.title.success"),
              type: "check",
            });
            this.$emit("refresh-user-tickets");
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => (this.redeemedSet = ""));
    },
    handleUpgrade(id) {
      CardsAPI.craftCard(id)
        .then((res) => {
          this.mapApiJsonCardUpgrade(1);
          this.fetchUserCards()
            .then((res) => {
              this.userCards = res;
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => {
          console.log(e);
          if (e.data.id) {
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: this.$t("e.data.id"),
              alertHeading: this.$t("alerts.title.error"),
              type: "danger",
              variant: "danger",
              dismissMessage: "ok-upcraft-alert",
            });
          } else {
            this.displayErrorMessage();
          }
        });
    },
    handlewishSuccess(title) {
      this.$emit("end-wish");
      this.wishedCardTitle = title;
      this.$store.commit("popups/setAlertBannerContent", {
        alertText: this.$t("trading_cards.popup.desired.message", {
          0: title,
        }),
        alertHeading: this.$t("alerts.title.success"),
        type: "check",
      });
    },
    handleCardSelect(obj) {
      this.selectedCard = obj.card;
      this.posX = obj.posX;
      this.posY = obj.posY;
    },
  },
};
</script>

<style lang="scss">
#album-container {
  padding: 60px 0 0 0;
  position: relative;
  z-index: 2;

  .card-row-container {
    padding-bottom: 25px;
  }
}
</style>
