var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading && !_vm.error)?_c('b-col',{attrs:{"id":"album-container","cols":"12"}},[_c('transition',{attrs:{"name":"inspect-card","duration":1000},on:{"enter":_vm.enter,"leave":_vm.leave}},[(_vm.selectedCard)?_c('CardInspector',{key:_vm.selectedCard.id,attrs:{"card":_vm.selectedCard},on:{"close":function($event){_vm.selectedCard = null}}}):_vm._e()],1),_vm._l((_vm.sets),function(set,index){return _c('div',{key:set.set,staticClass:"card-row-container"},[_c('SetRow',{attrs:{"set-index":index,"user-cards":_vm.userCardsInSet(set.set),"set":set,"cards":_vm.cardsInSet(set.set),"user-tickets":_vm.userTickets,"wish-mode":_vm.wishMode,"raffle-rewards":_vm.raffleRewards,"redeemed-set":_vm.redeemedSet},on:{"redeem":_vm.handleRedeem,"upgrade":_vm.handleUpgrade,"legendaryAlert":function($event){_vm.$store.commit('popups/setAlertBannerContent', {
          alertText: _vm.$t(
            'trading_cards.popup.not_desirable.legendary_message'
          ),
          alertHeading: _vm.$t('alerts.title.error'),
          variant: 'danger',
          type: 'danger',
        })},"maxAmountAlert":function($event){_vm.$store.commit('popups/setAlertBannerContent', {
          alertText: _vm.$t(
            'trading_cards.popup.not_desirable.max_amount_message'
          ),
          alertHeading: _vm.$t('alerts.title.error'),
          variant: 'danger',
          type: 'danger',
        })},"wishSuccess":_vm.handlewishSuccess,"selected":_vm.handleCardSelect}})],1)})],2):(_vm.error)?_c('ErrorPlaceholder',{staticClass:"w-100"}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }