<template>
  <b-table
    v-if="!error"
    id="next-raffle"
    small
    :items="items"
    :fields="fields"
    thead-class="head-row-text"
  >
    <template #table-caption>
      {{ $t("trading_cards.raffle.next_time") }}: {{ formatDate }}</template
    >
    <template v-slot:head()="item">{{ $t(item.label) }}</template>

    <template v-slot:cell(price_pot)="data">
      <div class="inline-cell">
        {{ rewardForSet(data.item.set) }}
        <div class="gold-coin bg-img ml-1"></div></div
    ></template>
  </b-table>
  <ErrorPlaceholder v-else />
</template>

<script>
import ErrorPlaceholder from "@/components/base/error-placeholder.vue";
export default {
  name: "NextRaffle",
  components: { ErrorPlaceholder },
  props: {
    items: {
      type: Array,
      required: true,
    },
    date: {
      type: Number,
      default: 0,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "set",
          label: "trading_cards.raffle.set",
        },
        {
          key: "price_pot",
          label: "raffle_logs.overview.column_title.price_pot",
        },
      ],
    };
  },
  computed: {
    formatDate() {
      let date = new Date(this.date);
      return date.toLocaleDateString(this.$store.state.locale, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    },
  },
  methods: {
    rewardForSet(set) {
      let setReward = this.items.filter((raffle) => raffle.set === set)[0];
      return setReward.users + " x " + this.formatNumber(setReward.amount);
    },
  },
};
</script>

<style lang="scss">
#next-raffle-content {
  .title-row {
    font-size: 30px;
  }
  .subtitle-row {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    height: 27px;
    font-size: 16px;
  }
  .prize-row {
    width: 100%;
    height: 27px;
    font-size: 24px;
    margin-bottom: 8px;

    .index-col {
      width: 24px;
      text-align: center;
      color: $light-blue;
      text-shadow: 0 0 2px rgba(0, 0, 0, 0.8), 0 0 2px rgba(0, 0, 0, 0.5),
        0 0 8px rgba(77, 229, 255, 0.53);
    }
    .prize-col {
      color: #fff4b2;
    }
    img {
      max-height: 100%;
    }
  }
}
</style>
