<template>
  <div id="raffle-results">
    <b-table
      v-if="!error"
      id="raffle-winners"
      small
      :items="sortedResults[0]"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      thead-class="head-row-text"
      @row-clicked="openDetailsModal"
    >
      <template v-slot:table-colgroup>
        <col key="amount" style="width: 25%" />
        <col key="username" style="width: 60%" />
        <col key="userlevel" style="width: 15%" />
      </template>
      <template #table-caption>
        {{ $t("raffle_logs.winners.title", { 0: " " }) + raffleDate }}</template
      >
      <template v-slot:head()="item">{{ $t(item.label) }}</template>

      <template v-slot:cell(username)="data">
        <div v-if="data.item.user" class="username-cell justify-content-center">
          <span
            class="user-name"
            :class="isSelf(data.item.user.id) ? 'text-green' : ''"
            >{{ shortenString(data.item.user.name, 40) }}</span
          >
        </div>
      </template>

      <template v-slot:cell(userlevel)="data">
        <span
          v-if="data.item.user"
          :class="isSelf(data.item.user.id) ? 'text-green' : ''"
          >{{ data.item.user.level }}</span
        >
      </template>
      <template v-slot:cell(amount)="data">
        <div v-if="data.item.amount" class="inline-cell">
          <span :class="isSelf(data.item.user.id) ? 'text-green' : ''">
            {{ formatNumber(data.value) }}</span
          >
          <div class="gold-coin bg-img ml-1"></div>
        </div>
      </template>
    </b-table>

    <tablePagination
      v-if="!error"
      v-model="currentPage"
      :per-page="perPage"
      :total-rows="results.length"
    />
    <ErrorPlaceholder v-else />
  </div>
</template>

<script>
import getPublicUserInfos from "@/mixins/getPublicUserInfos.js";
import tablePagination from "@/components/base/pagination.vue";
import ErrorPlaceholder from "@/components/base/error-placeholder.vue";
import { getCardRaffle } from "@/API/static-config.js";
export default {
  name: "RaffleResults",
  components: { tablePagination, ErrorPlaceholder },
  mixins: [getPublicUserInfos],
  props: {
    results: {
      type: Array,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      raffleDate: "",
      fields: [
        {
          key: "amount",
          label: "raffle_logs.winners.column_title.reward",
        },
        {
          key: "username",
          label: "raffle_logs.winners.column_title.user",
        },
        {
          key: "userlevel",
          label: "raffle_logs.winners.column_title.level",
        },
      ],
    };
  },
  computed: {
    sortedResults() {
      let arrCopy = this.results.slice();
      return arrCopy.map((array) => {
        let sortedArray = [];
        for (let i = array.length - 1; i >= 0; i--) {
          sortedArray.push(array[i]);
        }
        return sortedArray;
      });
    },

    showPagination() {
      if (this.totalPages <= this.perPage) {
        return false;
      } else return true;
    },
    isPrevButtonDisabled() {
      if (this.currentPage <= 1) {
        return true;
      } else {
        return false;
      }
    },
    isNextButtonDisabled() {
      if (this.currentPage >= this.totalPages) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    getCardRaffle()
      .then((res) => {
        if (!res) {
          this.raffleDate = "";
          return;
        }
        let dateBegin = res.data[Object.keys(res.data)[0]][0];
        this.raffleDate = new Date(dateBegin.created_at).toLocaleDateString(
          this.$store.state.locale,
          {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          }
        );
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    openDetailsModal(row) {
      // disable for date subheading rows
      if (row.date || row.user.deleted_at) return;
      this.fetchPublicUser(row.user_id);
    },
    isSelf(id) {
      return id === this.$store.getters["user/currentUser"].id;
    },
  },
};
</script>

<style lang="scss">
#raffle-results {
  padding: 0;

  #table-pagination {
    left: 130px;
    bottom: -56px;
  }
}
</style>
