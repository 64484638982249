<template>
  <div class="trading-card">
    <div class="card-front clickable" @click="handleCardFrontClick">
      <img
        class="card-bg"
        :src="card.card.image_urls.backgrounds.collected_url"
      />
      <div
        class="trading-card-title to-center-abs"
        :class="{ 'two-lines': lineBreak }"
        v-html="cardTitle"
      ></div>
      <img
        v-if="card.card.set"
        class="card-symbol"
        :src="card.card.image_urls.image_url"
      />
    </div>

    <div
      v-if="card.amount > 1 && !card.is_full"
      class="card-badge counter d-flex align-items-center justify-content-center bold bg-img to-center-abs text-normal"
    >
      {{ card.amount }}/{{ card.card.probability.probability + 1 }}
    </div>

    <div
      v-else-if="card.is_full && !buttonClicked"
      class="card-badge counter w-100 complete d-flex align-items-center justify-content-between bg-img to-center-abs"
    >
      <span class="pl-3 text-normal bold"> {{ card.amount }} x</span
      ><img
        draggable="false"
        src="@/assets/img/cards/checkmark-cards-full.svg"
      />
      <InfoBadge
        v-if="card.is_craftable"
        :id="`info-upgrade${card.card_id}`"
        :info-text="upgradeInfo"
        :tooltip-only="true"
        faq-link="cards"
      />
    </div>

    <div
      v-if="card.is_craftable && !buttonClicked"
      class="upcraft to-center-abs"
    >
      <button
        class="button-empty bg-img upgrade-button w-100 h-100"
        @click="handleUpgrade"
      >
        <span class="text-white text-normal medium position-absolute">+ 1</span>
      </button>
    </div>
  </div>
</template>

<script>
import * as CardsAPI from "@/API/cards.js";
import InfoBadge from "@/components/base/info-badge.vue";
export default {
  name: "TradingCard",
  components: { InfoBadge },
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      buttonClicked: false,
    };
  },
  computed: {
    cardTitle() {
      let localeTitle = this.$t(
        `trading_cards.cards.${this.card.card.set}_${this.card.card.number}`
      );
      let titleWords = localeTitle.split(" ");
      if (titleWords.length === 1) {
        return localeTitle;
      } else if (titleWords.length === 2) {
        return titleWords[0] + "<br />" + titleWords[1];
      } else if (titleWords.length === 3) {
        return titleWords[0] + " " + titleWords[1] + "<br />" + titleWords[2];
      } else if (titleWords.length === 4) {
        return (
          titleWords[0] +
          " " +
          titleWords[1] +
          " " +
          titleWords[2] +
          "<br />" +
          titleWords[3]
        );
      } else {
        return localeTitle;
      }
    },
    cardTitlePlain() {
      return this.$t(
        `trading_cards.cards.${this.card.card.set}_${this.card.card.number}`
      );
    },
    setTitle() {
      return (
        this.card.card.set.charAt(0).toUpperCase() + this.card.card.set.slice(1)
      );
    },
    lineBreak() {
      return this.cardTitle.includes("<br />");
    },

    selected() {
      return this.card.card_id === this.selectedCard;
    },
    upgradeInfo() {
      return this.$t("info_badge.cards.upgrade");
    },
    elixirInfo() {
      return this.$t("info_badge.cards.elixir");
    },
    counterInfo() {
      return this.$t("info_badge.cards.counter");
    },
  },
  mounted() {
    this.$root.$on("ok-upcraft-alert", () => (this.buttonClicked = false));
  },
  methods: {
    handleUpgrade() {
      this.playSoundTab();
      this.buttonClicked = true;
      this.$emit("upgrade", this.card.card_id);
    },
    handleCardFrontClick(e) {
      this.playSoundTab();
      if (this.selected) {
        this.$emit("selected", null);
      } else {
        this.$emit("selected", {
          card: this.card.card,
          posX: e.target.getBoundingClientRect().x,
          posY: e.target.getBoundingClientRect().y,
        });
      }
    },
  },
};
</script>
<style lang="scss">
.trading-card {
  position: relative;

  &:hover .card-front {
    &.normal {
      cursor: pointer;
      filter: $hover-shadow;
    }
  }
  .info-badge-orange {
    z-index: 100;
  }

  .card-bg {
    width: 100%;
  }

  .card-symbol {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .trading-card-title {
    color: white;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    bottom: 23%;
    font-size: 8px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);

    &.two-lines {
      line-height: 1.2;
      bottom: 20%;
    }
  }

  .card-badge {
    position: absolute;
    z-index: 2;
    &.counter {
      top: -40px;
      max-width: 89px;
      height: 24px;
      color: $light-blue;
      background-image: url(~@/assets/img/cards/display-cards-counter.svg);

      &.complete {
        color: $highlight-green;
        img {
          position: absolute;
          top: 0;
          right: -2px;
          height: 24px;
          width: 24px;
        }
      }
    }
  }

  .upcraft {
    bottom: -25px;
    width: 106px;
    height: 83px;
    @media (max-width: 1920px) {
      transform: scale(0.8);
    }
    button {
      background-image: url(~@/assets/img/cards/elixir-upgrade.png);

      span {
        left: 27px;
        bottom: 15px;
      }
    }
  }
}
</style>
