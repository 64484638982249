<template>
  <b-col cols="12 p-0">
    <b-row class="row-title" align-h="between" align-v="center"
      ><b-col class="d-inline-flex align-items-center">
        <div class="card-set-icon bg-img mr-5"></div>
        <span class="set-name white-shadow medium text-uppercase mr-5">{{
          $t(`trading_cards.sets.${set.set}`)
        }}</span> </b-col
      ><b-col
        v-if="!wishMode"
        class="redeem-row d-inline-flex align-items-center justify-content-end"
      >
        <template v-if="set.type === 'gold'">
          <div class="reward-type text-uppercase bold black-bg">
            <img draggable="false" src="@/assets/img/common/coin1.png" />

            <span class="bold text-white text-normal">
              {{ formatNumber(set.amount) }}
            </span>
          </div>
          <InfoBadge
            v-if="isSetComplete"
            :id="`info-redeem-set${setIndex}`"
            position="right"
            :info-text="$t('info_badge.cards.redeem_set_gold')"
            faq-link="cards"
            faq-article="360009873097"
        /></template>

        <template v-else>
          <div class="reward-type black-bg">
            <span class="text-uppercase bold light-yellow">
              {{ $t("trading_cards.button.raffle") }}:</span
            >
            <span
              class="text-white bold text-normal text-center"
              :style="{
                width: `${raffleReward[0].users.toString().length * 30}px`,
              }"
            >
              {{ raffleReward[0].users }} x
            </span>
            <img draggable="false" src="@/assets/img/common/coin1.png" />
            <span class="text-white text-normal bold">
              {{ formatNumber(raffleReward[0].amount) }}
            </span>
          </div>
          <InfoBadge
            v-if="isSetComplete"
            :id="`info-redeem-set${setIndex}`"
            position="right"
            :info-text="$t('info_badge.cards.redeem_set_ticket')"
            faq-link="cards"
            faq-article="360009873097"
        /></template>

        <div v-if="userTicketsInSet > 0" class="black-bg reward-type">
          <span class="text-uppercase bold light-yellow"
            >{{ $t("trading_cards.raffle_ticket>other") }}:</span
          ><span class="blue bold text-normal">{{ userTicketsInSet }}</span>
        </div>

        <button
          :class="[!isSetComplete && !wishMode ? 'disabled' : 'enabled']"
          class="button-empty bg-img text-white medium text-uppercase mr-4"
          @click="handleSetRedeem"
        >
          <span v-if="set.type === 'gold'">
            {{ $t("trading_cards.redeem_set_gold") }}</span
          >
          <span v-else> {{ $t("trading_cards.redeem_set_ticket") }}</span>
        </button></b-col
      ></b-row
    >
    <b-row class="set-row">
      <Spinner v-if="isBeingRedeemed" />

      <b-col
        v-for="card in cards"
        :key="card.id"
        lg="2"
        xl="1"
        class="card-col"
      >
        <TradingCard
          v-if="!isBeingRedeemed && userCard(card.id) && !wishMode"
          :card="userCard(card.id)"
          @upgrade="handleUpgrade"
          @selected="handleCardSelect"
        />
        <TradingCardBack
          v-else-if="!isBeingRedeemed && !userCard(card.id) && !wishMode"
          :card="card"
        />
        <TradingCardWish
          v-else-if="wishMode"
          :card="card"
          :user-card="userCard(card.id)"
          @legendaryAlert="$emit('legendaryAlert')"
          @maxAmountAlert="$emit('maxAmountAlert')"
          @wishSuccess="handleWishSuccess"
          @cardWishError="handleCardWishError"
        />
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import InfoBadge from "@/components/base/info-badge.vue";
import TradingCard from "@/components/cards/trading-card.vue";
import TradingCardBack from "@/components/cards/trading-card-back.vue";
import TradingCardWish from "@/components/cards/trading-card-wish.vue";
import Spinner from "@/components/animation/animation-spinner.vue";
export default {
  name: "SetRow",
  components: {
    TradingCard,
    TradingCardBack,
    InfoBadge,
    Spinner,
    TradingCardWish,
  },
  props: {
    set: {
      type: Object,
      required: true,
    },
    cards: {
      type: Array,
      required: true,
    },
    userCards: {
      type: Array,
      required: true,
    },

    userTickets: {
      type: Array,
      required: false,
      default: () => [],
    },

    wishMode: {
      type: Boolean,
      default: false,
    },
    setIndex: {
      type: Number,
      required: true,
    },
    raffleRewards: {
      type: Array,
      required: true,
    },
    redeemedSet: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    isSetComplete() {
      return this.userCards.length === this.cards.length;
    },
    userTicketsInSet() {
      let ticketsForThisSet = this.userTickets.filter(
        (ticket) => ticket.set === this.set.set
      );
      if (ticketsForThisSet.length > 0) {
        return ticketsForThisSet[0].amount;
      } else {
        return 0;
      }
    },
    raffleReward() {
      if (this.set.type === "gold") {
        return null;
      } else {
        return this.raffleRewards.filter(
          (reward) => reward.set === this.set.set
        );
      }
    },

    isBeingRedeemed() {
      return this.redeemedSet === this.set.set;
    },
  },

  methods: {
    handleSetRedeem() {
      this.$emit("redeem", this.set.set);
    },
    handleUpgrade(id) {
      this.$emit("upgrade", id);
    },
    handleWishSuccess(title) {
      this.$emit("wishSuccess", title);
    },
    handleCardSelect(obj) {
      this.$emit("selected", obj);
    },
    handleCardWishError(errorMessage) {
      this.$store.commit("popups/setAlertBannerContent", {
        alertText: errorMessage,
        alertHeading: this.$t("alerts.title.error"),
        type: "danger",
        variant: "danger",
      });
    },

    userCard(cardId) {
      let cardObject = this.userCards.find(
        (card) => card.card.id === cardId && card.amount > 0
      );
      if (cardObject !== undefined) {
        return cardObject;
      } else {
        return null;
      }
    },
  },
};
</script>
<style lang="scss">
.row-title {
  color: white;
  background-color: rgba(5, 13, 28, 0.31);
  height: 57px;
  img {
    max-height: 26px;
  }
  .card-set-icon {
    background-image: url(~@/assets/img/nav-menu/nav-icon-cards.png);
    width: 32px;
    height: 32px;
  }
  .set-name {
    font-size: 32px;
  }
  .redeem-row {
    height: 42px;
    .black-bg {
      background-color: black;
      height: 32px;

      border-radius: 16px;
    }
    button {
      width: 250px;
      height: 42px;
      background-image: url(~@/assets/img/cards/btn-cards-set-grey-default.svg);
      font-size: 14px;
      text-shadow: 0 2px 4px black;
      &.enabled {
        background-image: url(~@/assets/img/cards/btn-cards-set-green-default.svg);
      }
      &.disabled {
        pointer-events: none;
      }
    }
  }

  .reward-type {
    height: 32px;
    padding: 0 20px 0 16px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -20px;

    img {
      width: 16px;
      height: 16px;
      filter: drop-shadow(0 2px 3px black);
    }

    span {
      padding: 0 4px 0 4px;
      &.light-yellow {
        color: #fff8b7;
      }
    }
  }
}
.set-row {
  background-color: $dark-blue-06;
  padding: 0 30px;

  .card-col {
    -webkit-transform: translate3d(0, 0, 0);
    z-index: 1;
    margin-bottom: 38px;
    margin-top: 38px;
    padding: 16px 9px 0 9px;

    &.big {
      z-index: 5;
    }
  }
}

.spinner-padding {
  position: relative;
  padding-top: 45px;
  padding-left: 25%;
}
</style>
