<template>
  <div id="overview-results">
    <b-table
      v-if="!error"
      id="raffle-overview"
      small
      :items="userTickets"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :total-pages="totalPages"
      sort-by="date_raffle"
      thead-class="head-row-text"
    >
      <template v-slot:table-colgroup>
        <col key="set" style="width: 20%" />
        <col key="price_pot" style="width: 20%" />
        <col key="date_raffle" style="width: 20%" />
        <col key="amount" style="width: 10%" />
        <col key="state" style="width: 30%" />
      </template>
      <template #table-caption>{{$t("raffle_logs.overview.headline")}}</template>
      <template v-slot:head()="item">{{ $t(item.label) }}</template>

      <template v-slot:cell(set)="data">
        {{ $t(`trading_cards.sets.${data.item.set}`) }}
      </template>

      <template v-slot:cell(price_pot)="data">
        <div v-if="rewardForSet(data.item.set)" class="inline-cell">
          {{ rewardForSet(data.item.set) }}
          <img
            draggable="false"
            src="@/assets/img/common/coin1.png"
            alt="gold"
            style="height: 16px"
          />
        </div>
      </template>
      <template v-slot:cell(date_raffle)="data">
        {{ raffleDate(data.item) }}
      </template>
      <template v-slot:cell(amount)="data">
        <template v-if="data.item.amount > 0">{{ data.item.amount }}</template
        ><template v-else>{{ " " }}</template>
      </template>
      <template v-slot:cell(state)="data">
        <template v-if="data.item.amount > 0"
          ><span class="text-grey">{{
            $t("raffle_logs.overview.column_title.state_pending")
          }}</span></template
        >
        <template v-else>
          <div class="inline-cell" v-html="hasUserWon(data.item)"></div
        ></template>
      </template>
    </b-table>

    <tablePagination
      v-if="!error"
      v-model="currentPage"
      :per-page="perPage"
      :total-rows="userTickets.length"
    />
    <ErrorPlaceholder v-else class="mt-5" />
  </div>
</template>

<script>
import tablePagination from "@/components/base/pagination.vue";
import ErrorPlaceholder from "@/components/base/error-placeholder.vue";
export default {
  name: "OverviewResults",
  components: { tablePagination, ErrorPlaceholder },
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    userTickets: {
      type: Array,
      required: false,
      default: () => [],
    },
    userLogs: {
      type: Array,
      required: false,
      default: () => [],
    },
    rewards: {
      type: Array,
      required: false,
      default: () => [],
    },
    date: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: "set",
          label: "raffle_logs.overview.column_title.set",
        },
        {
          key: "price_pot",
          label: "raffle_logs.overview.column_title.price_pot",
        },
        {
          key: "date_raffle",
          label: "raffle_logs.overview.column_title.date_raffle",
        },
        {
          key: "amount",
          label: "raffle_logs.overview.column_title.amount",
        },
        {
          key: "state",
          label: "raffle_logs.overview.column_title.state",
        },
      ],
    };
  },

  computed: {
    showPagination() {
      return this.userTickets.length > this.perPage;
    },
    isPrevButtonDisabled() {
      return this.currentPage <= 1;
    },
    isNextButtonDisabled() {
      return this.currentPage >= this.totalPages;
    },
    totalPages() {
      return Math.ceil(this.userTickets.length / this.perPage);
    },
  },
  methods: {
    rewardForSet(set) {
      let setReward = this.rewards.filter((reward) => reward.set === set)[0];
      if (!setReward || !setReward.users) return null;
      return setReward.users + " x " + this.formatNumber(setReward.amount);
    },
    raffleDate(raffle) {
      let date;
      // amount > 0 means user has active tickets and raffle is in future
      if (raffle.amount > 0) {
        date = new Date(this.date);
      } else {
        //is it a winner ticket? then use logged raffle date
        let wins;
        if (this.userLogs[0]) {
          wins = this.userLogs[0].find(
            (log) => log.user_card_raffle_ticket_id === raffle.id
          );
        }
        if (wins) {
          let raffleDate = wins.log.replace("Card Raffle at ", "");
          date = new Date(raffleDate);
        } else {
          //no winner ticket
          date = new Date(raffle.updated_at);
        }
      }
      return date.toLocaleDateString(this.$store.state.locale, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    },

    hasUserWon(item) {
      if (!this.userLogs[0] || this.userLogs[0].length === 0) {
        return this.$t("raffle_logs.overview.column_title.state_no_win");
      } else {
        let wins = this.userLogs[0].find(
          (log) => log.user_card_raffle_ticket_id === item.id
        );
        if (wins) {
          return (
            "<span class='text-green'>" +
            this.formatNumber(wins.amount) +
            "</span><div class='gold-coin bg-img ml-1'></div>"
          );
        } else
          return (
            "<span class='text-red'>" +
            this.$t("raffle_logs.overview.column_title.state_no_win") +
            "</span>"
          );
      }
    },
  },
};
</script>

<style lang="scss">
#overview-results {
  padding: 0;

  #table-pagination {
    left: 0;
    bottom: 0;
  }
}
</style>
