<template>
  <TabbedPage>
    <template v-slot:top-tabbed-content>
      <b-row
        v-if="!wishMode && albumView"
        id="countdown-section"
        class="text-normal to-center-abs"
        align-h="end"
        align-v="center"
        no-gutters
      >
      </b-row>
      <div
        v-else-if="wishMode && albumView"
        id="countdown-section"
        class="card-wish-enabled to-center-abs"
      >
        <img
          id="cancel-button"
          draggable="false"
          src="@/assets/img/profile/cancel.svg"
          alt="cancel"
          class="mr-3"
          @click="cancelWish"
        />
        <span class="text-white text-uppercase bold">{{
          $t("trading_cards.desire.info")
        }}</span>
      </div></template
    >
    <template v-if="!wishMode" v-slot:nav-tabs>
      <NavTab
        v-for="tab in contentTabs"
        :key="tab.title"
        :href="tab.path"
        :label="$t(tab.title)"
        :german-only="tab.germanOnly"
      />
    </template>
    <template v-if="!wishMode" v-slot:additional-tab-content>
      <Countdown
        v-if="nextRaffle"
        countdown-type="raffle"
        :time="nextRaffle"
        :with-time-diff="true"
    /></template>

    <template v-slot:main-content>
      <router-view
        :wish-mode="wishMode"
        :next-raffle-date="nextRaffle"
        :user-tickets="userTickets"
        @end-wish="handleEndWish"
        @refresh-user-tickets="fetchUserTickets"
    /></template>
  </TabbedPage>
</template>

<script>
import * as CardsAPI from "@/API/cards.js";
import Countdown from "@/components/base/countdown.vue";
import NavTab from "@/components/base/nav-tab.vue";
import TabbedPage from "@/views/TabbedPage.vue";

export default {
  name: "CardsPage",
  components: {
    TabbedPage,
    Countdown,
    NavTab,
  },
  props: {
    wishModeExternal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contentTabs: [
        { title: "trading_cards.title", path: "/cards" },
        { title: "raffle_logs.raffle", path: "/cards/winners" },
        { title: "content_tab.info", path: "/cards/faq", germanOnly: true },
      ],
      nextRaffle: null,
      wishMode: false,
      userTickets: [],
    };
  },
  computed: {
    albumView() {
      return this.$route.name === "album";
    },

    forcedWishMode() {
      return this.$store.getters["cards/getCardsForcedWishMode"];
    },
  },

  watch: {
    forcedWishMode: function (newVal) {
      if (newVal) {
        this.wishMode = newVal;
        this.$store.commit("cards/setCardsForcedWishMode", false);
      }
    },
  },
  mounted: async function () {
    if (this.wishModeExternal) {
      this.wishMode = true;
    }
    let date = await CardsAPI.getNextRaffle();

    this.nextRaffle = new Date(date.replace(" ", "T")).getTime();
    this.fetchUserTickets();

    this.$store.commit("cards/setCardsForcedWishMode", false);
  },
  methods: {
    async getWishTime() {
      let seconds = await CardsAPI.getNextWishTime();
      let now = Date.now();
      // Timer needs milliseconds until deadline
      let wishTime = seconds * 1000 + now;
      this.$store.commit("chat/setCardWishTime", wishTime);
    },
    async fetchUserTickets() {
      let tickets = await CardsAPI.fetchUserTickets();
      this.userTickets = tickets;
    },
    cancelWish() {
      this.playSoundButtonUpcraft();
      this.wishMode = false;
      if (this.wishModeExternal) {
        this.$router.go(-1);
      }
    },
    handleEndWish() {
      this.getWishTime().then(() => {
        this.wishMode = false;
        if (this.wishModeExternal) {
          this.$router.go(-1);
        }
      });
    },
  },
};
</script>
<style lang="scss">
#countdown-section {
  position: absolute;
  width: 98%;
  top: 80px;
  right: 50px;
  z-index: 10;

  &.card-wish-enabled {
    top: -30px;
  }

  #info-card-album {
    margin-top: -40px;
    margin-left: -40px;
  }

  .card-wish-countdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    font-size: 16px;
    img {
      width: 32px;
      height: 32px;
      position: absolute;
      left: 0;
      bottom: 23px;
      z-index: 1;
    }
    .countdown-digital {
      color: $light-blue;
      background-image: linear-gradient(#0e6473, #050d1c 41%, #050d1c);
      box-shadow: 0 0 6px $light-blue;
      width: 60%;
      text-align: center;
    }
    .lock {
      height: 24px;
      width: 20px;
      background: url(~@/assets/img/common/lock-gold.png) center/100% 100%
        no-repeat;
      position: absolute;
      right: -10px;
      bottom: 4px;
      filter: grayscale(0%);
      transform: rotate(10deg);
    }
  }
}

#cancel-button {
  cursor: pointer;
  &:hover {
    filter: $hover-shadow;
  }
}
</style>
