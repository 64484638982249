<template>
  <div
    class="card-inspector bg-img clickable"
    :style="{
      'background-image': `url('${card.image_urls.backgrounds.collected_url}')`,
    }"
    @click="$emit('close')"
  >
    <img
      draggable="false"
      class="position-absolute w-100 h-100"
      :src="card.image_urls.image_url"
    />
    <div class="to-center-abs text-field text-center text-uppercase text-white">
      {{ $t(`trading_cards.cards.${card.set}_${card.number}`) }}
    </div>
    <div class="close-button"></div>
  </div>
</template>
<script>
export default {
  name: "CardInspector",
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.card-inspector {
  width: 20.125rem;
  height: 31.25rem;
  max-width: 322px;
  max-height: 500px;
  pointer-events: auto;
  position: fixed;
  z-index: 100;
  .text-field {
    width: 160px;
    height: 41px;
    bottom: 111px;
    font-size: 20.5px;
    line-height: 1;
  }
  .close-button {
    right: -15px;
    top: -15px;
  }
}
</style>
